(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/dialog"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/tooltip", "single-spa-vue", "primevue/toastservice", "vue-i18n", "vue", "@netvision/lib-api-gateway", "primevue/toast", "primevue/dialog"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/dialog")) : factory(root["primevue/tooltip"], root["single-spa-vue"], root["primevue/toastservice"], root["vue-i18n"], root["vue"], root["@netvision/lib-api-gateway"], root["primevue/toast"], root["primevue/dialog"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_dec9__) {
return 